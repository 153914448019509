import { useContext, useMemo } from 'react';

import { GridSettingsContext } from 'context';
import { Loader, PageLayout } from 'components';
import { useFetchCandidates, useOperatorsFetch, useFetchTeamNames, useFetchCandidatesStatuses } from 'api';

import CandidatesGrid from './candidates-grid';
import FiltersSidebar from './filters-sidebar';

const Dashboard = () => {
  const { gridSettings, columnsStoredSettings } = useContext(GridSettingsContext);
  const {
    data: candidatesSearchResults,
    isLoading: candidatesFetchInProgress,
    error: candidatesFetchError,
  } = useFetchCandidates(gridSettings, columnsStoredSettings);
  const {
    data: statusesSearchResult,
    isLoading: statusesFetchInProgress,
    error: statusesFetchError,
  } = useFetchCandidatesStatuses(gridSettings, columnsStoredSettings);
  const { data: operators, isLoading: operatorsFetchInProgress, error: operatorsFetchError } = useOperatorsFetch();
  const { teamNames, teamNamesIsLoading, teamNamesIsError } = useFetchTeamNames();

  const teamNamesMap = useMemo(
    () =>
      teamNames.reduce<Record<string, string>>(
        (acc, team) => ({
          ...acc,
          [team.id]: team.name,
        }),
        {}
      ),
    [teamNames]
  );

  const isLoadingData = candidatesFetchInProgress || operatorsFetchInProgress || teamNamesIsLoading;

  const error = candidatesFetchError || statusesFetchError || operatorsFetchError || teamNamesIsError;

  if (error) {
    throw error;
  }

  return (
    <PageLayout headerProps={{ pageTitle: 'Dashboard' }}>
      <FiltersSidebar
        sx={{ backgroundColor: '#FAFAFA' }}
        operators={operators}
        teams={teamNames}
        statusCounters={statusesSearchResult?.statusCounters}
        statusesIsLoading={statusesFetchInProgress}
      />
      <CandidatesGrid
        sx={{ flex: 1, minWidth: '500px' }}
        candidates={candidatesSearchResults?.candidates}
        candidatesAmount={candidatesSearchResults?.total}
        teamNamesMap={teamNamesMap}
      />
      <Loader open={isLoadingData} />
    </PageLayout>
  );
};

export default Dashboard;
